<script setup lang="ts">
const { getPaletteForPostType } = useTheme();
const { sharedPost, sharedImage, isShareOpen } = usePostShare();

const postType = computed(() => sharedPost.value?.type);

const title = computed(() =>
  postType.value
    ? {
        [PostType.Inspiration]: "Udostępnij inspirację",
        [PostType.Professional]: "Udostępnij partnera",
        [PostType.Architect]: "Udostępnij architekta",
        [PostType.Relaxation]: "Udostępnij relaks",
        [PostType.Contractor]: "Udostępnij wykonawcę",
        [PostType.Project]: "Udostępnij projekt",
      }[postType.value]
    : "Udostępnij inspirację"
);

const { postToGrid } = useGrid();
const gridItem = computed(() => postToGrid(sharedPost, sharedImage));

const runtimeConfig = useRuntimeConfig();

const link = computed(() => {
  if (gridItem.value?.link) {
    const url = new URL(
      gridItem.value.link,
      runtimeConfig.public.baseUrl || "http://localhost:3000"
    );

    if (sharedImage.value?.id) {
      url.searchParams.set("image", sharedImage.value.id);
    }

    return url.href;
  }

  return undefined;
});

const { isMobile } = useUserDevice();

const boardCover = computed(
  () => sharedImage.value?.file?.id || sharedPost.value?.cover?.file?.id
);
</script>

<template>
  <AppDialog
    v-model:is-open="isShareOpen"
    v-bind="{ title }"
    class="post-share"
    :class="[
      getPaletteForPostType(postType).scrollbar,
      getPaletteForPostType(postType).selection,
    ]"
    :drawer="isMobile"
  >
    <template v-if="boardCover" #drawer-bg>
      <NuxtPicture
        provider="directus"
        :src="boardCover"
        class="grow w-full h-full"
        :img-attrs="{ class: 'w-full h-full object-cover' }"
        quality="65"
        :alt="sharedPost.value?.title"
        preload
      />
    </template>

    <template #side>
      <div
        class="post-share__side absolute inset-0 grid grid-cols-[22.75rem] justify-center items-center p-16"
        :class="[getPaletteForPostType(postType).bg]"
      >
        <PostGridItem :item="gridItem" :interactive="false" />
      </div>
    </template>

    <template #default>
      <div class="post-share__content h-full">
        <div
          class="post-share__content-wrapper h-full flex flex-col items-stretch gap-6"
        >
          <p v-if="isMobile" class="text-center">{{ sharedPost.title }}</p>
          <p v-else>Proszę, wybierz opcję</p>

          <PostShareSocial
            :link="link"
            :title="sharedPost.title"
            class="grow"
          />

          <div class="flex flex-col items-stretch justify-center">
            <p class="text-center">lub</p>
          </div>

          <PostShareLink :link="link" />
        </div>
      </div>
    </template>
  </AppDialog>
</template>
